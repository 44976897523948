import React from "react"
import { Link, graphql } from "gatsby"
import parse from "html-react-parser"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Pagination } from "../components/Pagination/Pagination "
function BlogTags({ data, pageContext }) {
  const posts = data.allWpPost.nodes
  //   pagination data
  const { currentPage, pageCount } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === pageCount
  const prevPage = currentPage - 1 === 1 ? "" : currentPage - 1
  const nextPage = currentPage + 1
  // pagination data
  const paginationData = {
    currentPage,
    pageCount,
    isFirst,
    isLast,
    prevPage,
    nextPage,
  }

  if (!posts.length) {
    return (
      <Layout isHomePage>
        <Seo title="All posts" />

        <p>
          No blog posts found. Add posts to your WordPress site and they'll
          appear here!
        </p>
      </Layout>
    )
  }

  return (
    <Layout isHomePage>
      <h1 style={{ fontSize: "50px", color: "orange", margin: "50px 0" }}>
        {pageContext.name}
      </h1>
      <ol style={{ listStyle: `none` }}>
        {posts.map(post => {
          const title = post.title

          return (
            <li key={post.uri}>
              <article
                className="post-list-item"
                itemScope
                itemType="http://schema.org/Article"
              >
                <header>
                  <h2>
                    <Link to={post?.uri} itemProp="url">
                      <span itemProp="headline">{parse(title)}</span>
                    </Link>
                  </h2>
                  <small>{post?.date}</small>
                </header>
                <section itemProp="description">{parse(post?.excerpt)}</section>
              </article>
            </li>
          )
        })}
        <Pagination
          paginationData={paginationData}
          base={`tag/${pageContext.slug}`}
        />
      </ol>
    </Layout>
  )
}
export const Head = () => <Seo title="All Posts" />
export const pageQuery = graphql`
  query ($skip: Int!, $limit: Int!, $slug: String!) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $limit
      skip: $skip
      filter: { tags: { nodes: { elemMatch: { slug: { eq: $slug } } } } }
    ) {
      nodes {
        title
        excerpt
        uri
        featuredImage {
          node {
            slug

            localFile {
              name
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        author {
          node {
            name
          }
        }
      }
    }
    seo: wpPage {
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          srcSet
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          srcSet
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
    }
  }
`
export default BlogTags
